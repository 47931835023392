import { useRouteMatch, Switch } from "react-router-dom";
import PrivateRoute from "../navigation/PrivateRoute";
import { People } from "./People";
import { Person } from "./Person";
import { NewPerson } from "./PersonNew";

export function TeamRoutes() {
    let match = useRouteMatch();
  
    return (
      <Switch>
        <PrivateRoute path={`${match.path}/new`}>
            <NewPerson />
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/:id`}>
            <Person />
          </PrivateRoute>
          <PrivateRoute path={`${match.path}`}>
            <People />
          </PrivateRoute>
      
        </Switch>
  
    );
  }