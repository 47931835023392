"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteShearer = void 0;
const client_1 = require("@apollo/client");
const DELETE_SHEARER = client_1.gql `
mutation DeleteShearer($id: ID!) {
    deleteShearer(id: $id)
}
`;
const useDeleteShearer = () => client_1.useMutation(DELETE_SHEARER);
exports.useDeleteShearer = useDeleteShearer;
