import * as React from "react";

const AUTH_KEY ="@countingsheep_token";

export const setAuthToken = (token: string) => {
  if (token) {
    localStorage.setItem(AUTH_KEY, token);
  } else {
    localStorage.removeItem(AUTH_KEY);
  }
};

export const getAuthToken = (): string | null => {
  return localStorage.getItem(AUTH_KEY);
}

export const clearToken = () => {
  localStorage.removeItem(AUTH_KEY);
};

export interface AuthContextModel {
  signOut: () => void;
  emailPassswordSignIn: (email: string, password: string) => void;
  signingIn: boolean;
  isAuthenticated: boolean | undefined;
  getIdToken: () => Promise<string | undefined>;
}

export const AuthContext = React.createContext<AuthContextModel>({
  signOut: () => {},
  emailPassswordSignIn: () => {},
  signingIn: false,
  isAuthenticated: false,
  getIdToken: () => Promise.resolve(undefined),
});
