import { useTallyBookQuery } from "@countingsheep/queries";
import * as T from "semantic-ui-react";
import React from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { NewTallyRow } from "./NewTallyRow";
import { TallyBookBodySkeleton } from "./TallyBookRowSkeleton";
import { TallyContext } from "./TallyContext";
import { TallyRow } from "./TallyRow";

export const TableBody = () => {
  const {
    tallyDate: [tallyDate],
  } = React.useContext(TallyContext);

  const debouncedDate = useDebounce<Date>(tallyDate, 500);

  const [tallies, dayLoading] = useTallyBookQuery(debouncedDate);

  const TallyRows = React.useMemo(
    () =>
      tallies?.tallies.map(
        (t) => <TallyRow key={`row_tally__${t.id}`} Row={t} />,
        tallies
      ),
    [tallies]
  );
  if (dayLoading || !tallies) {
    return <TallyBookBodySkeleton />;
  }
  return (
    <T.Table.Body>
      {TallyRows}
      <NewTallyRow />
    </T.Table.Body>
  );
};
