import React from "react";
import * as T from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { TallyContext } from "./TallyContext";
import { UserTypeContext } from "@countingsheep/contexts";

export const TallyTableHeaderRunsView = () => {
  const {
    toDate: [toDateDate, setToDateDate],
  } = React.useContext(TallyContext);
  const userTypeLabel = React.useContext(UserTypeContext);

  const showToDate = false;

  return (
    <T.Table.Header>
      <T.TableRow>
        <T.TableHeaderCell key={"shearer_header"} rowSpan="2">
          {userTypeLabel.TypeLabel}
        </T.TableHeaderCell>
        <T.TableHeaderCell key={"runs_header"} colSpan="6" textAlign={"center"}>
          Runs
        </T.TableHeaderCell>
        <T.TableHeaderCell
          key={"day_header"}
          rowSpan="2"
          style={{ width: "60px" }}
          textAlign={"center"}
        >
          Day Total
        </T.TableHeaderCell>
        {showToDate && (
          <T.TableHeaderCell
            key={"todate_header"}
            rowSpan="2"
            style={{ width: "60px" }}
            textAlign={"center"}
          >
            <DatePicker
              onChange={(d) => {
                if (d) setToDateDate(d as Date);
              }}
              selected={toDateDate}
              dateFormat={"'Since:' dd/MM/yyyy"}
            />
          </T.TableHeaderCell>
        )}
      </T.TableRow>
      <T.TableRow>
        <T.TableHeaderCell
          key={"1st_header"}
          style={{ width: "50px" }}
          textAlign={"center"}
        >
          1st
        </T.TableHeaderCell>
        <T.TableHeaderCell
          key={"2_header"}
          style={{ width: "50px" }}
          textAlign={"center"}
        >
          2nd
        </T.TableHeaderCell>
        <T.TableHeaderCell
          key={"3_header"}
          style={{ width: "50px" }}
          textAlign={"center"}
        >
          3rd
        </T.TableHeaderCell>
        <T.TableHeaderCell
          key={"4_header"}
          style={{ width: "50px" }}
          textAlign={"center"}
        >
          4th
        </T.TableHeaderCell>
        <T.TableHeaderCell
          key={"5_header"}
          style={{ width: "50px" }}
          textAlign={"center"}
        >
          5th
        </T.TableHeaderCell>
        <T.TableHeaderCell
          key={"6_header"}
          style={{ width: "50px" }}
          textAlign={"center"}
        >
          6th
        </T.TableHeaderCell>
      </T.TableRow>
    </T.Table.Header>
  );
};
