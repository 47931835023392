import { useHistory } from "react-router-dom";
import { Grid, Placeholder, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { PersonTile } from "./PersonTile";
import { People } from "./types";

export function PeopleTiles({
  people,
  loading,
}: {
  people: People;
  loading: boolean;
}) {
  const h = useHistory();

  return (
    <Wrapper>
      <Grid columns={3} stackable>
        {loading ? (
          <>
            <TilePlaceholderRow />
            <TilePlaceholderRow />
            <TilePlaceholderRow />
          </>
        ) : (
          people
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((person) => (
              <Grid.Column key={`grid-column_${person.id}`}>
                <PersonTile
                  key={`tile_${person.id}`}
                  person={person}
                  onClick={() => {
                    console.log("Click");
                    h.push(`/team/${person.id.toString()}`);
                  }}
                />
              </Grid.Column>
            ))
        )}
      </Grid>
    </Wrapper>
  );
}

const TilePlaceholderRow = () => (
  <>
    <Grid.Column>
      <Segment raised>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    </Grid.Column>

    <Grid.Column>
      <Segment raised>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    </Grid.Column>

    <Grid.Column>
      <Segment raised>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    </Grid.Column>
  </>
);

const Wrapper = styled.div`
  // display: grid;
  // grid-template-columns: 33% 34% 33%;
  // // grid-template-columns: repeat(auto-fit, minmax(310px, max-content));
  // grid-gap: 16px;
  // justify-content: center;
  // height: 100%;
  padding: 30px;
`;
