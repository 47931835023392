"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TallyFragment = exports.TallyRunFragment = void 0;
const client_1 = require("@apollo/client");
exports.TallyRunFragment = client_1.gql `
  fragment TallyRunFragment on TallyRun {
    __typename
    count
    run
  }
`;
exports.TallyFragment = client_1.gql `
  fragment TallyFragment on Tally {
    id
    date
    rateCents
    shearerID
    userID
    memo
    status
    invoiceID
    shearer {
      name
      id
      imageUrl
    }
    runs {
      ...TallyRunFragment
    }
  }
  ${exports.TallyRunFragment}
`;
