import React from "react";
import { Tallies_tallies } from "@countingsheep/queries/dist/src/tallies/__graphqlTypes/Tallies";
import * as T from "semantic-ui-react";
import Modal from "../Modal";
import { InvoiceItemStatus } from "@countingsheep/queries";
import { UserTag } from "@countingsheep/components-web";
import { TallyRowMoreMenu } from "./TallyRowMoreMenu";
import { TallyRowRuns } from "./TallyRow.Runs";
import { useTallyContext } from "./TallyContext";
import { useHistory } from "react-router-dom";

interface TallyRowProps {
  Row: Tallies_tallies;
}
export const TallyRow: React.FC<TallyRowProps> = ({ Row }) => {
  const [showNote, setShowNotes] = React.useState(false);
  const [memo, setMemo] = React.useState("");
  const {
    showDayView: [showDayView],
  } = useTallyContext();

  if (!Row) {
    return null;
  }

  return (
    <RowPopup Row={Row}>
      <T.Table.Row key={`tallyrow-table-row_${Row.id}`}>
        <T.TableCell
          style={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: showDayView ? 260 : undefined,
          }}
        >
          <UserTag name={Row.shearer.name} size={"medium"} />

          <TallyRowMoreMenu shearerID={Row.shearer.id} />
        </T.TableCell>
        <TallyRowRuns Row={Row} />

        <Modal open={showNote} clickOutside={() => setShowNotes(false)}>
          <div className={"memo_modal_content"}>
            <T.Form>
              <T.TextArea
                value={memo}
                onChange={(v) => setMemo(v.currentTarget.value)}
              />
              <div className={"button-cont"}>
                <T.Button onClick={() => setShowNotes(false)}>Cancel</T.Button>
                <T.Button
                  onClick={() => {
                    setShowNotes(false);
                  }}
                >
                  Save
                </T.Button>
              </div>
            </T.Form>
          </div>
        </Modal>
      </T.Table.Row>
    </RowPopup>
  );
};

const RowPopup: React.FC<{
  Row: Tallies_tallies;
  children?: React.ReactNode;
}> = ({ children, Row }) => {
  const h = useHistory();

  const showInvoice = () => {
    h.push(`/invoice/${Row.invoiceID}`);
  };
  if (Row.status === InvoiceItemStatus.INVOICED) {
    return (
      <T.Popup
        content={
          <T.PopupContent>
            {"Read only: These items have already been invoiced "}
            <T.Button onClick={showInvoice}>Show</T.Button>
          </T.PopupContent>
        }
        trigger={children}
        position={"top center"}
        hoverable
      />
    );
  }
  return <>{children}</>;
};
