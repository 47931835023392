import * as T from "semantic-ui-react";
import { ShearerSelect } from "@countingsheep/components-web";
import { DateIsoTZ } from "@countingsheep/dates";
import { useAddTally } from "@countingsheep/queries";
import { CircularProgress } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import { TallyContext } from "./TallyContext";
import { SingleNumberReadonlyCell } from "./SingleNumberReadOnlyCell";

export const NewTallyRow: React.FC<{ children?: React.ReactNode }> = () => {
  const {
    tallyDate: [tallyDate],
    showDayView: [showDayView],
  } = React.useContext(TallyContext);

  const history = useHistory();

  const [addTally, adding] = useAddTally();

  const createRowForNewShearer = async (shearerID?: number) => {
    if (!shearerID) {
      return;
    }
    // Check if row exists
    await addTally({
      shearerId: shearerID.toString(),
      memo: "",
      date: DateIsoTZ(tallyDate),
    });
  };

  const addNewShearer = () => {
    history.push("/team/new", { returnTo: "/tally" });
  };

  return (
    <T.Table.Row>
      <T.TableCell style={{ width: "160px" }}>
        {adding ? (
          <CircularProgress />
        ) : (
          <ShearerSelect
            hideSelected
            onChange={createRowForNewShearer}
            addNew={addNewShearer}
          />
        )}
      </T.TableCell>
      {showDayView ? (
        <></>
      ) : (
        <>
          <SingleNumberReadonlyCell />
          <SingleNumberReadonlyCell />
          <SingleNumberReadonlyCell />
          <SingleNumberReadonlyCell />
          <SingleNumberReadonlyCell />
          <SingleNumberReadonlyCell />
          <SingleNumberReadonlyCell />
        </>
      )}
    </T.Table.Row>
  );
};
