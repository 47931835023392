import React from "react";
import { InlineEdit } from "@countingsheep/components-web";
import {
  GetPersonDefaultPrice,
  GetPersonDefaultPriceVariables,
} from "./__graphqlTypes/GetPersonDefaultPrice";
import { formatCents } from "@countingsheep/common";
import { gql, useQuery } from "@countingsheep/queries";

export const GET_PERSON_DEFAULT_PRICE = gql`
  query GetPersonDefaultPrice($shearersId: ID) {
    me {
      id
      defaultRate
      shearers(id: $shearersId) {
        id
        defaultPriceCents
      }
    }
  }
`;

export function DefaultPrice({ id }: { id: string }) {
  const { data, loading, error } = useQuery<
    GetPersonDefaultPrice,
    GetPersonDefaultPriceVariables
  >(GET_PERSON_DEFAULT_PRICE, {
    // fetchPolicy: "cache-first",
    variables: {
      shearersId: id,
    },
  });
  const [defaultPriceString, setDefaultPriceString] = React.useState<
    string | undefined
  >(undefined);

  React.useEffect(() => {
    if (data?.me) {
      if (
        data.me.shearers?.length === 1 &&
        data.me.shearers[0].defaultPriceCents !== null
      ) {
        setDefaultPriceString(
          formatCents(data.me.shearers[0].defaultPriceCents)
        );
      } else {
        setDefaultPriceString(formatCents(data.me.defaultRate));
      }
    }
  }, [data, data?.me, data?.me?.shearers]);

  const onConfirm = () => {};

  if (error) {
    return null;
  }
  return (
    <InlineEdit
      value={defaultPriceString}
      key={"price"}
      label={"Default Price"}
      width={250}
      onConfirm={onConfirm}
      loading={loading}
    />
  );
}
