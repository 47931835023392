"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTallyBookQuery = void 0;
const client_1 = require("@apollo/client");
const tallyFragment_1 = require("./tallyFragment");
const TALLY_BOOK = client_1.gql `
query Tallies($filter: TallyFilter!) {
  tallies(filter: $filter) {
    ...TallyFragment
  }
}
${tallyFragment_1.TallyFragment}
`;
const mapQueryResult = (result) => {
    return {
        ...result,
    };
};
const useTallyBookQuery = (date) => {
    const { data, loading } = client_1.useQuery(TALLY_BOOK, {
        variables: {
            filter: {
                forDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)),
            }
        }
    });
    return [data, loading, data];
};
exports.useTallyBookQuery = useTallyBookQuery;
