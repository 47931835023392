import {
  InvoiceItemStatus,
  Tallies_tallies,
  useAddTallyRun,
} from "@countingsheep/queries";
import React from "react";
import { SingleNumberReadonlyCell } from "./SingleNumberReadOnlyCell";

import { TallyCell } from "./TallyCell";
import { useTallyContext } from "./TallyContext";

interface RRun {
  count: number;
  run: number;
  id?: any;
}

const RowNumbers = [0, 1, 2, 3, 4, 5] as const;
type RowNumber = typeof RowNumbers[number];
type RunMap = Record<RowNumber, RRun>;

const emptyRun = (run: number): RRun => ({
  count: 0,
  run,
});
const defaultRunMap: () => RunMap = () => ({
  0: emptyRun(0),
  1: emptyRun(1),
  2: emptyRun(2),
  3: emptyRun(3),
  4: emptyRun(4),
  5: emptyRun(5),
});

export function TallyRowRuns({ Row }: { Row: Tallies_tallies }) {
  const runs = React.useMemo(() => Row.runs, [Row.runs]);

  const rowRuns = React.useMemo<RunMap>(
    () =>
      runs.reduce<RunMap>((acc, run) => {
        acc[RowNumbers[run.run]] = { ...run, id: `${Row.id}_${run.run}` };
        return acc;
      }, defaultRunMap()),
    [runs, Row.id]
  );

  const total = React.useMemo(
    () =>
      Row.runs.reduce<number>((acc, run) => {
        return acc + run.count;
      }, 0),
    [Row.runs]
  );

  const {
    showDayView: [showDayView],
  } = useTallyContext();

  const [addRun] = useAddTallyRun();

  const changeCount = React.useCallback(
    async (count: number, run: RRun) => {
      await addRun({
        tallyID: Row.id,
        run: run.run,
        count,
      });
    },
    [Row.id, addRun]
  );

  const updateDayCount = React.useCallback(
    async (count: number) => {
      const run = { ...rowRuns[0] };
      // Add to day 1
      const difference = count - total;
      run.count = run.count + difference;
      await addRun({
        tallyID: Row.id,
        run: 0,
        count: run.count,
      });
    },
    [Row.id, addRun, rowRuns, total]
  );

  const Cells = React.useMemo(
    () =>
      Object.values(rowRuns).map((run) => (
        <TallyCell
          key={`tallyrow_cell_${run.run}_row-${Row.id}`}
          count={run.count}
          save={async (count) => {
            await changeCount(count, run);
          }}
          readonly={Row.status === InvoiceItemStatus.INVOICED}
        />
      )),
    [Row.id, Row.status, changeCount, rowRuns]
  );

  if (showDayView) {
    return (
      <TallyCell
        key={`tallrow_cell_day_view_${Row.id}`}
        count={total}
        save={async (count) => {
          // await changeCount(count, currentRun);
          await updateDayCount(count);
        }}
      />
    );
  }

  return (
    <>
      {Cells}
      <SingleNumberReadonlyCell key={`tally_cell_total_${Row.id}`}>
        {total}
      </SingleNumberReadonlyCell>
    </>
  );
}
