import React from "react";
import { AddDays, formatDate } from "@countingsheep/dates";
import { useParams } from "react-router-dom";
import { parse } from "date-fns";

type TallyContextType = {
  tallyDate: [Date, (date: Date) => void];
  toDate: [Date, (date: Date) => void];
  showDayView: [boolean, (v: boolean) => void];
};
export const TallyContext = React.createContext<TallyContextType>({
  tallyDate: [new Date(), () => {}],
  toDate: [new Date(), () => {}],
  showDayView: [false, () => {}],
});

function dateToUrl(d: Date) {
  const newURL = window.location.href.split("tally");
  window.history.replaceState(
    "",
    "",
    newURL[0] + `tally/${formatDate(d, "yyyy-MM-dd")}`
  );
}

export const TallyContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { date } = useParams<{ date?: string }>();

  const parsedDate = React.useMemo(
    () => (date ? parse(date, "yyyy-MM-dd", new Date()) : new Date()),
    [date]
  );

  const toDateStart = React.useMemo(
    () => AddDays(parsedDate, -7),
    [parsedDate]
  );

  const [tallyDate, setTallyDate] = React.useState(parsedDate);

  const setTallyDateWithURLParams = (d: Date) => {
    dateToUrl(d);

    setTallyDate(d);
  };
  const toDate = React.useState(toDateStart);
  const showDayView = React.useState(false);

  return (
    <TallyContext.Provider
      value={{
        tallyDate: [tallyDate, setTallyDateWithURLParams],
        toDate,
        showDayView,
      }}
    >
      {children}
    </TallyContext.Provider>
  );
};

export const useTallyContext = () => React.useContext(TallyContext);
