"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProfile = void 0;
const client_1 = require("@apollo/client");
const ProfileFragment_1 = require("./ProfileFragment");
const PROFILE = client_1.gql `
  query Profile {
    me {
      ...ProfileFragment
    }
  }
  ${ProfileFragment_1.ProfileFragment}
`;
const useProfile = () => {
    const { data, loading } = client_1.useQuery(PROFILE, {
        fetchPolicy: "cache-first",
    });
    return { data, loading };
};
exports.useProfile = useProfile;
