import React from "react";
import { Button } from "@countingsheep/components-web";
// import { loadStripe } from "@stripe/stripe-js";
import C from "../../config";
import { useSubscriptionPortal } from "@countingsheep/queries";

const loadStripe = (key: any): Promise<any> => Promise.resolve();

const SubscriptionView: React.FC<{ children?: React.ReactNode }> = () => {
  const [getPortal, data, loading] = useSubscriptionPortal();
  const stripePromise = loadStripe(C.STRIPE_KEY);

  const manageSubscription = () => {
    getPortal();
  };

  React.useEffect(() => {
    const loadSession = async (sessionId: string) => {
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;
      if (!stripe || !sessionId) return;

      await stripe.redirectToCheckout({
        sessionId,
      });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    };

    if (data && data.me.subscriptionPortal !== null) {
      if (data.me.subscriptionPortal.startsWith("http")) {
        window.open(data.me.subscriptionPortal, "_self");
      } else {
        void loadSession(data.me.subscriptionPortal);
      }
    }
  }, [data, stripePromise]);

  return (
    <div>
      {!loading ? (
        <Button onClick={manageSubscription}>Manage Subscription</Button>
      ) : (
        <Button disabled>Loading...</Button>
      )}
    </div>
  );
};

export default SubscriptionView;
