"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileFragment = void 0;
const client_1 = require("@apollo/client");
exports.ProfileFragment = client_1.gql `
fragment ProfileFragment on User {
    id
    name
    email
    firebaseID
    stripeId
    phone
    image
}
`;
