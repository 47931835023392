import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Wrapper } from "../components/common/Wrapper";
import {
  Invoices,
  InvoiceWrapper,
  NewInvoiceWrapper,
} from "@countingsheep/invoicing";

import Tally from "../components/Tally/Tally";
import Landing from "../landing";
import Login from "../login/login";
import { LogoutView } from "../login/logout";
import { TeamRoutes } from "../people/PeopleRoutes";
import { ProfileView } from "../profile";
import Subscription from "../profile/subscription";
import PrivateRoute from "./PrivateRoute";

export const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <Route path="/" exact={true}>
          <Landing />
        </Route>

        <Route path="/app" exact={true}>
          <div>APP</div>
        </Route>

        <Route path="/reset-password/:token" exact={true}>
          <div />
        </Route>
        {/* <Route path="/invoice/:fileId">
            <div />
          </Route> */}

        <PrivateRoute path="/protected">
          <div />
        </PrivateRoute>
        <PrivateRoute path="/profile">
          <ProfileView />
        </PrivateRoute>
        <PrivateRoute path="/job/new">
          <div />
        </PrivateRoute>
        <PrivateRoute path="/shearers">
          <div />
        </PrivateRoute>
        <Route path="/team">
          <TeamRoutes />
        </Route>
        <PrivateRoute path="/pay/new">
          <div />
        </PrivateRoute>
        <PrivateRoute path="/pay">
          <div />
        </PrivateRoute>
        <PrivateRoute path="/payslip/:id">
          <div />
        </PrivateRoute>
        <PrivateRoute path="/invoices">
          <Invoices />
        </PrivateRoute>

        <PrivateRoute path="/invoice/new/:shearerID?">
          <Wrapper>
            <NewInvoiceWrapper />
          </Wrapper>
        </PrivateRoute>

        <PrivateRoute path="/invoice/:id">
          <Wrapper>
            <InvoiceWrapper />
          </Wrapper>
        </PrivateRoute>

        <PrivateRoute path="/logout">
          <LogoutView />
        </PrivateRoute>
        <PrivateRoute path="/tally/:date?">
          <Tally />
        </PrivateRoute>
        <PrivateRoute path={"/subscription"}>
          <Subscription />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};
