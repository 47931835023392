import React from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";
import { IPhoneX } from "react-device-frames";
import PlayStore from "../components/assets/playstore.png";
import AppStore from "../components/assets/appstore.svg";
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});
// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

const HomepageHeading = ({ mobile }: { mobile: boolean }) => (
  <Container text style={{ backgroundColor: "#004ba0" }}>
    <Image
      src={"https://storage.googleapis.com/shearing/Icon1024.png"}
      size={"small"}
      centered
    />
    <Header
      as="h1"
      content="The Counting Sheep App"
      inverted
      style={{
        fontSize: mobile ? "2em" : "4em",
        fontWeight: "normal",
        marginBottom: 0,
      }}
    />
    <Header
      as="h2"
      content="We never get tired of it."
      inverted
      style={{
        fontSize: mobile ? "1.5em" : "1.7em",
        fontWeight: "normal",
        marginTop: mobile ? "0.5em" : "1.5em",
      }}
    />
  </Container>
);

const DesktopContainer: React.FC<{ children?: React.ReactNode }> = (p) => {
  const [, setShowFixedMenu] = React.useState(false);

  return (
    <Media greaterThan="mobile">
      <Visibility
        once={false}
        onBottomPassed={() => setShowFixedMenu(true)}
        onBottomPassedReverse={() => setShowFixedMenu(false)}
      >
        <Segment
          textAlign="center"
          style={{
            minHeight: 350,
            padding: "1em 0em",
            backgroundColor: "#004ba0",
          }}
          vertical
        >
          <HomepageHeading mobile={false} />
        </Segment>
      </Visibility>

      {p.children}
    </Media>
  );
};

const MobileContainer: React.FC<{ children?: React.ReactNode }> = (p) => {
  const [sidebarOpened, setSideBarOpened] = React.useState(false);

  return (
    <Media
      // as={Sidebar.Pushable}
      at="mobile"
    >
      <Sidebar
        as={Menu}
        animation="push"
        inverted
        onHide={() => setSideBarOpened(false)}
        vertical
        visible={sidebarOpened}
      >
        <Menu.Item as="a" active>
          Home
        </Menu.Item>
        <Menu.Item as="a">Work</Menu.Item>
        <Menu.Item as="a">Company</Menu.Item>
        <Menu.Item as="a">Careers</Menu.Item>
        <Menu.Item as="a">Log in</Menu.Item>
        <Menu.Item as="a">Sign Up</Menu.Item>
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened}>
        <Segment
          inverted
          textAlign="center"
          style={{ minHeight: 350, padding: "1em 0em" }}
          vertical
        >
          <Container>
            <Menu inverted pointing secondary size="large">
              <Menu.Item onClick={() => setSideBarOpened(true)}>
                <Icon name="sidebar" />
              </Menu.Item>
              <Menu.Item position="right">
                <Button as="a" inverted>
                  Log in
                </Button>
                <Button as="a" inverted style={{ marginLeft: "0.5em" }}>
                  Sign Up
                </Button>
              </Menu.Item>
            </Menu>
          </Container>
          <HomepageHeading mobile />
        </Segment>

        {p.children}
      </Sidebar.Pusher>
    </Media>
  );
};

const ResponsiveContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  // @ts-ignore
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: "8em 0em" }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <img alt={"Play Store"} src={PlayStore} height={50} />
            <img alt={"App Store"} src={AppStore} height={40} />
          </Grid.Column>
          <Grid.Column floated="right" width={6}>
            <IPhoneX
              screenshot={"https://storage.googleapis.com/shearing/tally.png"}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center"></Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </ResponsiveContainer>
);
export default HomepageLayout;
