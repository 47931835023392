import * as T from "semantic-ui-react";
import { SingleNumberReadonlyCell } from "./SingleNumberReadOnlyCell";

export const TallyBookRowSkeleton = () => (
  <T.Table.Row>
    <TallyBookRowUserCellSkeleton />
    <SingleNumberReadonlyCell />
    <SingleNumberReadonlyCell />
    <SingleNumberReadonlyCell />
    <SingleNumberReadonlyCell />
    <SingleNumberReadonlyCell />
    <SingleNumberReadonlyCell />

    <SingleNumberReadonlyCell />
  </T.Table.Row>
);

export const TallyBookBodySkeleton = () => (
  <T.Table.Body>
    <TallyBookRowSkeleton />
    <TallyBookRowSkeleton />
    <TallyBookRowSkeleton />
  </T.Table.Body>
);

export const TallyBookRowUserCellSkeleton = () => (
  <T.TableCell style={{ width: "160px" }}>
    <T.Placeholder>
      <T.Placeholder.Header image>
        <T.Placeholder.Line />
        <T.Placeholder.Line />
      </T.Placeholder.Header>
    </T.Placeholder>
  </T.TableCell>
);
