"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditShearer = void 0;
const client_1 = require("@apollo/client");
const shearerFragement_1 = require("./shearerFragement");
const EDIT_SHEARER = client_1.gql `
  mutation EditShearerDetails($input: EditShearer!, $id: ID!) {
    updateShearer(input: $input, id: $id) {
      ...ShearerFragment
    }
  }
  ${shearerFragement_1.ShearerFragment}
`;
const useEditShearer = () => client_1.useMutation(EDIT_SHEARER);
exports.useEditShearer = useEditShearer;
