"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetShearer = void 0;
const client_1 = require("@apollo/client");
const shearerFragement_1 = require("./shearerFragement");
const SHEARER = client_1.gql `
  query GetShearer($id: ID!) {
    shearers(id: $id) {
      ...ShearerFragment
    }
  }
  ${shearerFragement_1.ShearerFragment}
`;
const useGetShearer = (id) => {
    const result = client_1.useQuery(SHEARER, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables: {
            id: id.toString(),
        },
    });
    const shearer = result.data && result.data.shearers.length > 0
        ? result.data?.shearers[0]
        : undefined;
    return {
        ...result,
        data: shearer,
    };
};
exports.useGetShearer = useGetShearer;
