import { colours } from "@countingsheep/common/lib";
import { isToday } from "date-fns";
import { useTallyContext } from "./TallyContext";
import DatePicker from "react-datepicker";
import * as T from "semantic-ui-react";

export const TallyDatePicker = () => {
  const {
    tallyDate: [tallyDate, setTallyDate],
    showDayView: [showDayView, setShowDayView],
  } = useTallyContext();

  return (
    <div className={"tally__date"}>
      <div style={{ width: 85 }} />
      <div className={"tally__date"}>
        <T.Button
          style={{ backgroundColor: "#ececec" }}
          icon
          onClick={() => {
            var d = new Date(tallyDate);
            d.setHours(-24);
            console.log(d);
            setTallyDate(d);
          }}
        >
          <T.Icon name={"arrow left"} />
        </T.Button>
        <DatePicker
          onChange={(d) => {
            if (d) setTallyDate(d as Date);
          }}
          selected={tallyDate}
          dateFormat={"dd/MM/yyyy"}
        />
        <T.Button
          style={{ backgroundColor: "#ececec" }}
          icon
          onClick={() => {
            var d = new Date(tallyDate);
            d.setHours(+24);
            setTallyDate(d);
          }}
        >
          <T.Icon name={"arrow right"} />
        </T.Button>
      </div>
      <div style={{ width: 65 }}>
        <T.Button
          style={{
            width: 40,
            backgroundColor: colours.lightGreen,
            color: "#fff",
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onClick={() => setShowDayView(!showDayView)}
        >
          <T.Icon
            name={!showDayView ? "calendar outline" : "grid layout"}
            width={40}
            style={{ margin: 0 }}
          />
        </T.Button>
      </div>
      <div style={{ width: 85 }}>
        {!isToday(tallyDate) && (
          <T.Button
            style={{
              width: 85,
              backgroundColor: colours.lightGreen,
              color: "#fff",
            }}
            onClick={() => setTallyDate(new Date())}
          >
            Today
          </T.Button>
        )}
      </div>
    </div>
  );
};
