import React from "react";

export const Wrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <div
    style={{
      display: "flex",
      flex: 1,
      justifyContent: "center",
    }}
  >
    {children}
  </div>
);
