"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLogin = void 0;
const client_1 = require("@apollo/client");
const LOGIN = client_1.gql `
mutation Login($email: String!, $password: String!) { 
  auth {
    login(email: $email, password: $password)
  }
} 
`;
const useLogin = () => {
    const mutation = client_1.useMutation(LOGIN);
    return mutation;
};
exports.useLogin = useLogin;
