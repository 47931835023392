import React from "react";
import { Route, useHistory } from "react-router-dom";
import { Header, Redirect } from "@countingsheep/components-web";
import Sidebar from "../layouts/Sidebar";
import { useAuth } from "@countingsheep/contexts";
import Config from "../config";

interface PrivateRouteProps {
  path: string;
  children?: React.ReactNode;
}

const stripVariable = (path: string) => path.substring(0, path.indexOf("/:"));

const continuePath = (path: string) => {
  if (path.length === 0) {
    return "";
  }
  return `?continue=${encodeURIComponent(stripVariable(path))}`;
};

const PrivateRoute: React.FC<PrivateRouteProps> = (p) => {
  const { isAuthenticated } = useAuth();
  const hist = useHistory();

  if (isAuthenticated === undefined) {
    return (
      <Redirect
        to={`${Config.AUTH_URL}${continuePath(p.path)}`}
        // to={Config.AUTH_URL}
        // search={`continue=${encodeURIComponent(stripVariable(p.path))}`}
      />
    );
  }
  if (!isAuthenticated) {
    return <Redirect to={`${Config.AUTH_URL}/logout${continuePath(p.path)}`} />;
  }

  return (
    <Route
      exact
      path={p.path}
      render={
        ({ location }) => (
          <>
            <Header
              onLogoutClick={() => {
                hist.push("/logout");
              }}
              onProfileClick={() => {
                hist.push("/profile");
              }}
            />
            <div className={"app__wrapper"}>
              <Sidebar />
              <div className={"app__content"}>{p.children}</div>
              <div className={"right_side"} />
            </div>
          </>
        )
        // } else return (
        //   <Redirect
        //     to={{
        //       pathname: "/login",
        //       state: { from: location }
        //     }}
        //   />
        // )
      }
    />
  );
};

export default PrivateRoute;
