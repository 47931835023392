import React from "react";
import { Redirect } from "react-router-dom";
import { AuthContext, AuthContextModel } from "../auth";
import Loading from "../components/Loading";
import LoginForm from "./loginRegister";

const Login: React.FC<{ children?: React.ReactNode }> = () => {
  const { emailPassswordSignIn, signingIn, isAuthenticated } =
    React.useContext<AuthContextModel>(AuthContext);

  return isAuthenticated && !signingIn ? (
    <Redirect to={{ pathname: "tally" }} />
  ) : signingIn ? (
    <Loading />
  ) : (
    <LoginForm action={emailPassswordSignIn} actionTitle={"Login"} />
  );
};

export default Login;
