"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNotInvoicedItems = void 0;
const client_1 = require("@apollo/client");
const NOT_INVOICED_ITEMS = client_1.gql `
query NotInvoicedItems($shearerID: ID!) {
  shearers(id:$shearerID) {
    id
    tallies(filter: { status: DRAFT }) {
        id
        date
        status
        count
        memo
        rateCents
    }
  }
}
`;
const useNotInvoicedItems = (shearerID) => {
    const { data, loading } = client_1.useQuery(NOT_INVOICED_ITEMS, {
        variables: {
            shearerID: shearerID ? shearerID : '',
        }
    });
    if (!shearerID) {
        return [undefined, true];
    }
    return [data, loading];
};
exports.useNotInvoicedItems = useNotInvoicedItems;
