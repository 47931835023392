import React from "react";
import SubscriptionView from "./subscription";
import { useProfile, useEditUser } from "@countingsheep/queries";
import {
  SinglePersonView,
  GenericPerson,
} from "../components/person/SinglePersonView";
import { PageContainer } from "@countingsheep/components-web";

export const ProfileView: React.FC<{ children?: React.ReactNode }> = (
  props
) => {
  const { data: user, loading } = useProfile();
  const [editUser] = useEditUser();

  const update = async (val: GenericPerson) => {
    await editUser({
      variables: { input: val },
    });
  };

  return (
    <PageContainer title={"Profile"}>
      <SinglePersonView
        value={
          user?.me
            ? {
                ...user!.me,
              }
            : undefined
        }
        loading={loading}
        update={update}
      >
        <SubscriptionView />
      </SinglePersonView>
    </PageContainer>
  );
};
