import React from "react";
import "firebase/auth";
import "firebase/storage";
import { AppRouter } from "./navigation/router";
import "./App.scss";
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { AlertTemplate } from "./components/error/alert";
import {
  CountingSheepWebContexts,
  useAuth,
  UserTypeProvider,
} from "@countingsheep/contexts";
import { CountingSheepApolloProvider } from "@countingsheep/queries";
import Config from "./config";
import { Loading } from "@countingsheep/components-web";
import { LogoutView } from "./login/logout";

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

function FirebaseApp() {
  return (
    <CountingSheepWebContexts
      firebaseConfig={{
        apiKey: Config.FIREBASE_API_KEY,
        projectId: Config.FIREBASE_PROJECT_ID,
        databaseURL: Config.FIREBASE_DATABASE_URL,
        authDomain: Config.FIREBASE_AUTH_DOMAIN,
        storageBucket: Config.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
      }}
      config={{}}
    >
      <AppWrappedInAuth />
    </CountingSheepWebContexts>
  );
}

function AppWrappedInAuth() {
  const { getToken, signOut, isAuthenticated } = useAuth();

  const getTokenInternal = React.useCallback(
    () => Promise.resolve(getToken()),
    [getToken]
  );
  const signOutInternal = React.useCallback(() => signOut(), [signOut]);

  if (isAuthenticated === undefined) {
    return <Loading />;
  }
  return isAuthenticated ? (
    <CountingSheepApolloProvider
      apiUrl={Config.API_URL}
      getToken={getTokenInternal}
      loading={<Loading />}
      logoutOnError={signOutInternal}
    >
      <App />
    </CountingSheepApolloProvider>
  ) : (
    <LogoutView />
  );
}

function App() {
  return (
    <UserTypeProvider>
      <AlertProvider template={AlertTemplate} {...options}>
        <div className={"app"}>
          <AppRouter />
        </div>
      </AlertProvider>
    </UserTypeProvider>
  );
}

export default FirebaseApp;
