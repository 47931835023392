"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddTallyRun = void 0;
const client_1 = require("@apollo/client");
const tallyFragment_1 = require("./tallyFragment");
const ADD_TALLY_RUN = client_1.gql `
mutation AddTallyRun($input: NewRun!) {
  createRun(input: $input) {
    ...TallyRunFragment
  }
}
${tallyFragment_1.TallyRunFragment}
`;
const useAddTallyRun = () => {
    const [useAddTallyRun, { loading }] = client_1.useMutation(ADD_TALLY_RUN, {
        refetchQueries: ['Tallies'],
    });
    const add = (input) => {
        useAddTallyRun({
            variables: {
                input
            }
        });
    };
    return [add, loading];
};
exports.useAddTallyRun = useAddTallyRun;
