import { useGetShearer } from "@countingsheep/queries";
import React from "react";
import { useParams } from "react-router";
import { SinglePersonView } from "../components/person/SinglePersonView";
import { PageContainer } from "@countingsheep/components-web";

export const Person: React.FC<{ children?: React.ReactNode }> = () => {
  const params = useParams();
  const { data, loading } = useGetShearer((params as any).id);

  return (
    <PageContainer title={loading || !data ? "" : data.name}>
      <SinglePersonView
        value={
          data
            ? {
                ...data,
              }
            : undefined
        }
        update={() => {}}
        loading={loading}
      />
    </PageContainer>
  );
};
