"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNewInvoiceDetails = void 0;
const client_1 = require("@apollo/client");
const NEW_INVOICE_DETAILS = client_1.gql `
query NewInvoiceDetails {
  me {
    nextInvoiceId
    invoicePrefix
  }
}
`;
const useNewInvoiceDetails = () => {
    const { data: newInvoiceDetails, loading } = client_1.useQuery(NEW_INVOICE_DETAILS);
    return [newInvoiceDetails, loading];
};
exports.useNewInvoiceDetails = useNewInvoiceDetails;
