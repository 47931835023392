"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gql = exports.useQuery = void 0;
const client_1 = require("@apollo/client");
const useQuery = (query, options) => {
    return client_1.useQuery(query, options);
};
exports.useQuery = useQuery;
var client_2 = require("@apollo/client");
Object.defineProperty(exports, "gql", { enumerable: true, get: function () { return client_2.gql; } });
