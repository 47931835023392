import { ShearerFragment, useGetShearers } from "@countingsheep/queries";
import React from "react";
import { PeopleGrid } from "./PeopleGrid";
import AutoSizer from "react-virtualized-auto-sizer";
import { Placeholder } from "semantic-ui-react";
import { PageContainer, NewButton } from "@countingsheep/components-web";
import { Link } from "react-router-dom";
import { PeopleTiles } from "./PeopleTiles";
import { shearerFragmentToPerson } from "./types";

export const People: React.FC<{ children?: React.ReactNode }> = () => {
  const { shearers, loading } = useGetShearers();

  const [showGrid] = React.useState(false);

  return (
    <PageContainer
      title={"Team"}
      rightElement={
        <Link to={"team/new"}>
          <NewButton>Add Person +</NewButton>
        </Link>
      }
    >
      {showGrid ? (
        loading ? (
          <GridPlaceholder />
        ) : (
          <AutoSizer>
            {(dimensions) => {
              return (
                <PeopleGrid
                  people={shearers as ShearerFragment[]}
                  {...dimensions}
                />
              );
            }}
          </AutoSizer>
        )
      ) : (
        <PeopleTiles
          people={shearers?.map(shearerFragmentToPerson) ?? []}
          loading={loading}
        />
      )}
    </PageContainer>
  );
};

const GridPlaceholder = () => (
  <div style={{ padding: 30 }}>
    <Placeholder>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
    <Placeholder>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </div>
);
