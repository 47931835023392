"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShearerFragment = void 0;
const client_1 = require("@apollo/client");
exports.ShearerFragment = client_1.gql `
fragment ShearerFragment on Shearer {
    name
    id
    email
    phone
    imageUrl
    defaultAddGst
    defaultAddSuper
    defaultPriceCents
}
`;
