"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddShearer = void 0;
const client_1 = require("@apollo/client");
const shearerFragement_1 = require("./shearerFragement");
const NEW_SHEARER = client_1.gql `
  mutation AddShearer($input: NewShearer!) {
    newShearer(input: $input) {
      ...ShearerFragment
    }
  }
  ${shearerFragement_1.ShearerFragment}
`;
const useAddShearer = (options) => {
    const mutation = client_1.useMutation(NEW_SHEARER, options);
    return mutation;
};
exports.useAddShearer = useAddShearer;
