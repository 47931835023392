import React from "react";
import { useAuth } from "@countingsheep/contexts";
import Config from "../config";

export const LogoutView: React.FC<{ children?: React.ReactNode }> = () => {
  const { signOut } = useAuth();

  React.useEffect(() => {
    const handleSignOut = () => {
      signOut();
      setTimeout(() => {
        window.location.assign(`${Config.AUTH_URL}/logout`);
      }, 500);
    };
    handleSignOut();
  }, [signOut]);

  return <div />;
};
