"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTally = void 0;
const client_1 = require("@apollo/client");
const tallyFragment_1 = require("./tallyFragment");
const UPDATE_TALLY = client_1.gql `
  mutation UpdateTally($input: UpdateTallyInputs!) {
    updateTally(input: $input) {
      ...TallyFragment
    }
  }
  ${tallyFragment_1.TallyFragment}
`;
const useUpdateTally = () => {
    const [updateTally, { loading }] = client_1.useMutation(UPDATE_TALLY, {
        refetchQueries: ["Tallies"],
    });
    const update = (input) => {
        updateTally({
            variables: {
                input,
            },
        });
    };
    return [update, { loading }];
};
exports.useUpdateTally = useUpdateTally;
