"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddTally = void 0;
const client_1 = require("@apollo/client");
const tallyFragment_1 = require("./tallyFragment");
const ADD_TALLY = client_1.gql `
mutation AddTally($input: NewTally!) {
  createTally(input: $input) {
    ...TallyFragment
  }
}
${tallyFragment_1.TallyFragment}
`;
const useAddTally = () => {
    const [addTally, { loading }] = client_1.useMutation(ADD_TALLY, {
        refetchQueries: ["Tallies"],
    });
    const add = (input) => {
        addTally({
            variables: {
                input
            }
        });
    };
    return [add, loading];
};
exports.useAddTally = useAddTally;
