"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCommon = void 0;
const client_1 = require("@apollo/client");
const useCommon = (query, options = {
    fetchPolicy: "cache-first",
}) => {
    const { data, loading } = client_1.useQuery(query, options);
    return { data, loading };
};
exports.useCommon = useCommon;
