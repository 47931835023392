"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateIsoTZ = exports.DateWithNoTime = exports.AddDays = exports.AWeekAgo = exports.formatDate = void 0;
const D = __importStar(require("date-fns"));
function formatDate(d, format = "dd/MM/yyyy") {
    if (typeof d === 'string') {
        return D.format(new Date(d), format);
    }
    return D.format(d, format);
}
exports.formatDate = formatDate;
const AWeekAgo = () => {
    return (0, exports.AddDays)(new Date(), -7);
};
exports.AWeekAgo = AWeekAgo;
const AddDays = (d, days) => D.addDays(d, days);
exports.AddDays = AddDays;
const DateWithNoTime = (d) => D.startOfDay(d);
exports.DateWithNoTime = DateWithNoTime;
const DateIsoTZ = (d) => {
    return D.format(d, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};
exports.DateIsoTZ = DateIsoTZ;
