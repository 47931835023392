import * as React from "react";
import * as T from "semantic-ui-react";
import styled from "styled-components";
import { TallyContext } from "./TallyContext";

interface TallyCellProps {
  count: number;
  save: (count: number) => void;
  readonly?: boolean;
}
export const TallyCell: React.FC<TallyCellProps> = ({
  count,
  save,
  readonly,
}) => {
  const {
    showDayView: [showDayView],
  } = React.useContext(TallyContext);
  const [localCount, setCount] = React.useState(count);
  return (
    <TallyCellCell
      className={"tally-cell"}
      data-customwidth={showDayView ? "100%" : "50px"}
    >
      <TellyCellContent>
        <T.Input
          type={"number"}
          value={localCount}
          onChange={(e) => {
            setCount(parseInt(e.target.value));
          }}
          onBlur={() => {
            if (localCount !== count) {
              save(localCount);
            }
          }}
          disabled={readonly}
        />
      </TellyCellContent>
    </TallyCellCell>
  );
};

const TallyCellCell = styled(T.TableCell)`
  padding: 0 !important;
  width: ${(props) => props["data-customwidth"]};
`;

const TellyCellContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 0px;
  > .ui.input {
    border: none;
    width: 100%;
    input {
      border: none;
      width: 100%;
      text-align: center;
      padding: 0;
    }
  }
`;
