"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserSettings = void 0;
const client_1 = require("@apollo/client");
const common_1 = require("../common");
const USER_SETTINGS = client_1.gql `
  query UserSettings {
    me {
      defaultRate
      includeGst
      superRate
    }
  }
`;
const useUserSettings = () => common_1.useCommon(USER_SETTINGS);
exports.useUserSettings = useUserSettings;
