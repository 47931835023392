import MoreHoriz from "@mui/icons-material/MoreHoriz";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router";

type TallyRowMoreMenuProps = {
    shearerID: string;
};
export const TallyRowMoreMenu: React.FC<TallyRowMoreMenuProps> = ({
    shearerID
}) => {

    const h = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
      setIsOpen(false);
        setAnchorEl(null);

  };

  const createInvoiceFromRow = () => {
    h.push(`/invoice/new/${shearerID}`);
  }

  return (
      <>
    <div
      style={{
        cursor: "pointer",
      }}
      aria-controls={isOpen ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? "true" : undefined}
      onClick={handleClick}
    >
      <MoreHoriz />
    </div>

    <Menu
        id="row-more-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={createInvoiceFromRow}>Create Invoice</MenuItem>
        <MenuItem onClick={handleClose}>Delete Row</MenuItem>
      </Menu> 
      </>
  );
};
