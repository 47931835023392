import React from "react";
import { AlertCustomOptionsWithType } from "react-alert";
// the style contains only the margin given as offset
// options contains all alert given options
// message is the alert message
// close is a function that closes the alert
export const AlertTemplate: React.FC<{
  style: React.CSSProperties;
  options: AlertCustomOptionsWithType;
  message: React.ReactNode;
  close: () => void;
}> = ({ style, options, message, close }) => (
  <div style={style}>
    {options.type === "info" && "!"}
    {options.type === "success" && ":)"}
    {options.type === "error" && ":("}
    {message}
    <button onClick={close}>X</button>
  </div>
);
