import React from 'react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import { ShearerFragment } from '@countingsheep/queries';
import { UserTag } from '@countingsheep/components-web';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 50 },
    { field: 'name', headerName: 'Name', width: 150, renderCell: cell => cell.value && cell.value !== null ? <UserTag name={(cell.value as string)} /> : null },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'created', headerName: 'Created', width: 200, sortComparator: (v1, v2) => new Date(v2 as string).getTime() - new Date(v1 as string).getTime() },
];


type PeopleGridProps = {
    people: ShearerFragment[];
    height: number;
    width:  number;
}

export const PeopleGrid: React.FC<PeopleGridProps> = ({
    height,
    people,
    width,
})  => {
    const h = useHistory();

    const onSelectUser = (id: GridRowId) => {
        h.push(`/team/${id.toString()}`);
    }

    return (
        <div style={{ height, width }}>
        <DataGrid
            isCellEditable={() => false}
            rows={people}
            columns={columns}
            onRowClick={(params) => {
                onSelectUser(params.id );
            }}
        />
        </div>
    );
}