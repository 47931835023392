"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddTallyQuery = void 0;
const client_1 = require("@apollo/client");
const common_1 = require("../common");
const QUERY = client_1.gql `
  query addTallyQuery($shearerId: ID!, $date: Time!) {
    shearer(id: $shearerId) {
      id
      name
      defaultPriceCents
    }
    tally(date: $date, shearerID: $shearerId) {
      id
      shearer {
        id
        defaultPriceCents
        name
      }
      rateCents
      count
    }
  }
`;
const useAddTallyQuery = (variables) => common_1.useCommon(QUERY, {
    variables,
    errorPolicy: "all",
});
exports.useAddTallyQuery = useAddTallyQuery;
