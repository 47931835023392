import React from "react";
import * as T from "semantic-ui-react";
import { UserTypeContext } from "@countingsheep/contexts";

export const TallyTableHeaderDayView = () => {
  const userTypeLabel = React.useContext(UserTypeContext);

  return (
    <T.Table.Header>
      <T.TableRow>
        <T.TableHeaderCell key={"shearer_header"}>
          {userTypeLabel.TypeLabel}
        </T.TableHeaderCell>
        <T.TableHeaderCell key={"runs_header"} textAlign={"center"}>
          Count
        </T.TableHeaderCell>
      </T.TableRow>
    </T.Table.Header>
  );
};
