import React from "react";
import giffy from "../assets/sheep_nobg_1080.gif";

const Loading: React.FC<{ text?: string }> = (props) => (
  <div
    style={{
      width: "100%",
      height: "100%",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <img
      alt={"Loading"}
      src={giffy}
      style={{
        width: 120,
        height: 120,
        marginTop: "15%",
      }}
    />
  </div>
);

export default Loading;
