import { colours } from "@countingsheep/common";
import { UserAvatar } from "@countingsheep/components-web";
import styled from "styled-components";
import { Person } from "./types";

export function PersonTile({
  person,
  onClick,
}: {
  person: Person;
  onClick?: () => void;
}) {
  return (
    <PersonTileWrapper clickable={onClick !== undefined}>
      <PersonTileContainer onClick={onClick}>
        <AvatarWrapper>
          <UserAvatar name={person.name} />
        </AvatarWrapper>
        <DetailWrapper>
          <Name>{person.name}</Name>
          {person.email.length > 0 && <Detail>{person.email}</Detail>}
          {person.phone.length > 0 && <Detail>{person.phone}</Detail>}
        </DetailWrapper>
      </PersonTileContainer>
    </PersonTileWrapper>
  );
}

const PersonTileWrapper = styled.div<{ clickable?: boolean }>`
  width: 100%;
  height: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

const PersonTileContainer = styled.div`
  padding: 4px 16px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const DetailWrapper = styled.div`
  width: 240px;
  margin-left: 20px;
`;
const Name = styled.h2`
  font-size: 18px;
`;

const Detail = styled.p`
  font-size: 10px;
  color: ${colours.blueGrey};
`;

const AvatarWrapper = styled.div`
  width: 40px;
`;
