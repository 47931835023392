import { colours } from "@countingsheep/common/lib/styles";
import * as React from "react";
import { Input } from "semantic-ui-react";
import { Button } from "@countingsheep/components-web";
import Config from "../config";

type Props = {
  actionTitle: string;
  action: (username: string, password: string) => void;
  children?: React.ReactNode;
};

const LoginRegister: React.FC<Props> = ({ action, actionTitle, children }) => {
  const [email, setEmail] = React.useState(Config.DEFAULT_EMAIL);
  const [password, setPassword] = React.useState(Config.DEFAULT_PASSWORD);

  const bg = process.env.PUBLIC_URL + "/20180825_132216.webp";

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: colours.darkPrimaryColour,
        display: "flex",
      }}
    >
      <div
        style={{
          width: "65%",
          background: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div
        style={{
          width: "35%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "15%",
          }}
        >
          <img
            alt={"The Counting Sheep App"}
            style={{
              height: 120,
              width: 120,
              justifyContent: "center",
            }}
            src={process.env.PUBLIC_URL + "logo512.png"}
          />
          <Input
            placeholder="Email"
            style={{
              color: "#fff",
              width: 300,
              margin: 10,
            }}
            value={email}
            onChange={(t) => setEmail(t.target.value)}
          />
          <Input
            placeholder="Password"
            type={"password"}
            style={{
              marginBottom: 10,
              width: 300,
            }}
            onChange={(t) => setPassword(t.target.value)}
            value={password}
          />
          <Button
            onClick={() => action(email, password)}
            style={{
              width: 300,
            }}
          >
            {actionTitle}
          </Button>
        </div>

        {children}
      </div>
    </div>
  );
};

export default LoginRegister;
