import * as React from "react";
import "./Sidebar.scss";
import * as _ from "ramda";
import { Link, useLocation } from "react-router-dom";

export const Sidebar: React.FC<{ children?: React.ReactNode }> = () => {
  let location = useLocation();

  const items = [
    {
      path: "/tally",
      label: "Tally Book",
    },
    {
      path: "/invoices",
      label: "Invoices",
    },
    {
      path: "/team",
      label: "Team",
    },
  ];
  return (
    <div className={`sidebar`}>
      <div className={"sidebar__content"}>
        {_.map((i) => {
          return (
            <div
              className={`sidebar__item ${
                i.path === location.pathname ? "sidebar__item-selected" : ""
              }`}
              key={i.path}
            >
              <Link to={i.path}>{i.label}</Link>
            </div>
          );
        }, items)}
      </div>
    </div>
  );
};

export default Sidebar;
