"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gqlClient = exports.authLink = void 0;
const context_1 = require("@apollo/client/link/context");
// import { client } from '@countingsheep/common/graphql';
const client_1 = require("@apollo/client");
const error_1 = require("@apollo/client/link/error");
const apollo_upload_client_1 = require("apollo-upload-client");
const httpLink = (apiUrl) => client_1.createHttpLink({
    credentials: "include",
    uri: apiUrl,
});
const authLink = (getToken) => context_1.setContext(async (_, { headers }) => {
    try {
        // const token = await getToken();
        const token = await getToken();
        // return the headers to the context so httpLink can read them
        if (token) {
            headers = {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            };
        }
        return {
            headers: headers,
        };
    }
    catch {
        return headers;
    }
});
exports.authLink = authLink;
const errorLink = (logoutOnError) => error_1.onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (message.includes("Access Denied") && logoutOnError) {
                console.log("Logging out user");
                logoutOnError();
                return;
            }
        });
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        if ("statusCode" in networkError) {
            console.log(`[Network error status code]: ${networkError.statusCode}`);
            if (networkError.statusCode === 401) {
                console.log("logg the fk out");
                if (logoutOnError) {
                    logoutOnError();
                    return;
                }
            }
        }
    }
    console.log("[someothererror]", response);
    return;
});
const uploadLink = (api) => apollo_upload_client_1.createUploadLink({ uri: api });
const gqlClient = (getToken, apiUrl, logoutOnError) => new client_1.ApolloClient({
    link: client_1.from([
        exports.authLink(getToken),
        errorLink(logoutOnError),
        uploadLink(apiUrl),
    ]),
    connectToDevTools: true,
    cache: new client_1.InMemoryCache({
        resultCaching: true,
        typePolicies: {
            Query: {
                fields: {
                    Shearer: {
                        merge: true,
                    },
                    // TallyRun: {
                    //   merge: true,
                    //   // keyFields: ['id']
                    // },
                    Tally: {
                        merge: true,
                    },
                    User: {
                        merge: true,
                    },
                },
            },
        },
    }),
});
exports.gqlClient = gqlClient;
