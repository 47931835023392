"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMyInvoices = exports.MY_INVOICES = void 0;
const client_1 = require("@apollo/client");
exports.MY_INVOICES = client_1.gql `
  query MyInvoices {
    me {
      id
      invoices {
        id
        date
        totalCents
        created
        formattedAmount
        reference
        items {
          description
          quantity
          cents
        }
        shearer {
          id
          name
        }
      }
    }
  }
`;
const mapQueryResult = (result) => {
    return {
        ...result,
    };
};
const useMyInvoices = () => {
    const { data, loading } = client_1.useQuery(exports.MY_INVOICES);
    return [
        data,
        loading,
        data && data.me?.invoices !== null
            ? data.me.invoices?.map(mapQueryResult)
            : undefined,
    ];
};
exports.useMyInvoices = useMyInvoices;
