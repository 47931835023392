import React from "react";
import { TallyContext } from "./TallyContext";
import { TallyTableHeaderDayView } from "./TallyTableHeader.DayView";
import { TallyTableHeaderRunsView } from "./TallyTableHeader.RunsView";

type TallyTableHeaderProps = {};
export const TallyTableHeader: React.FC<TallyTableHeaderProps> = () => {
  const {
    showDayView: [showDayView],
  } = React.useContext(TallyContext);

  return showDayView ? (
    <TallyTableHeaderDayView />
  ) : (
    <TallyTableHeaderRunsView />
  );
};
