"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubscriptionPortal = void 0;
const client_1 = require("@apollo/client");
const SUBSCRIPTION_PORTAL = client_1.gql `
  query Subscription {
    me {
      id
      subscriptionPortal
    }
  }
`;
const useSubscriptionPortal = () => {
    const [getPortal, { data, loading }] = client_1.useLazyQuery(SUBSCRIPTION_PORTAL);
    return [getPortal, data, loading];
};
exports.useSubscriptionPortal = useSubscriptionPortal;
