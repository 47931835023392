import * as React from "react";
import Modal from "react-modal";
import "./Modal.scss";

interface ModalProps {
  clickOutside?: () => void;
  open: boolean;
  children?: React.ReactNode;
}
Modal.setAppElement("#modal-root");

const customStyles: Modal.Styles = {
  content: {},
  overlay: {
    backgroundColor: "rgba(195, 189, 189, 0.75)",
  },
};

export const CSModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      className="modal-background"
      onRequestClose={props.clickOutside}
      isOpen={props.open}
      style={customStyles}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className={"modal__inner"}>{props.children}</div>
      </div>
    </Modal>
  );
};

export default CSModal;
