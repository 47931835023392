"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInvoiceDetails = void 0;
const client_1 = require("@apollo/client");
const INVOICE_DETAILS = client_1.gql `
  query InvoiceDetails($id: ID!) {
    invoice(id: $id) {
      id
      totalCents
      date
      reference
      addGST
      memo
      pdfUrl
      items {
        description
        quantity
        cents
        taxable
      }
      shearer {
        id
        name
      }
    }
  }
`;
const useInvoiceDetails = (id) => {
    const { data, loading } = client_1.useQuery(INVOICE_DETAILS, {
        variables: {
            id: id,
        },
    });
    return [data, loading];
};
exports.useInvoiceDetails = useInvoiceDetails;
