import { ShearerFragment } from "@countingsheep/queries";

export type Person = {
  name: string;
  id: string;
  email: string;
  phone: string;
  imageUrl: string | null;
};

export type People = Person[];

export const shearerFragmentToPerson = (fragment: ShearerFragment): Person => ({
  ...fragment,
});
