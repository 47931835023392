import React from "react";
// import { UploadFile } from "../common/FileUpload";
import { Checkbox, InlineEdit } from "@countingsheep/components-web";
import { Avatar, Divider } from "@mui/material";
import Loading from "../Loading";
import { DefaultPrice } from "./DefaultPrice";

export type GenericPerson = {
  email: string;
  phone: string;
  name: string;
  image?: string;
  id: string;
};

export type SinglePersonViewProps = {
  update: (val: GenericPerson) => void;
  value: GenericPerson | undefined;
  loading: boolean;
  children?: React.ReactNode;
};
export const SinglePersonView: React.FC<SinglePersonViewProps> = ({
  children,
  update,
  value,
  loading,
}) => {
  const editName = (name: string | number) => {
    if (!value || typeof name === "number") return;
    update({
      ...value,
      name,
    });
  };
  const editEmail = (email: string | number) => {
    if (!value || typeof email === "number") return;
    update({
      ...value,
      email,
    });
  };
  const editPhone = (phone: string | number) => {
    if (!value || typeof phone === "number") return;
    update({
      ...value,
      phone,
    });
  };

  return !loading && value ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          margin: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {value.image!! && (
          <Avatar
            src={value.image}
            sx={{ width: 120, height: 120 }}
            style={{
              marginBottom: 8,
            }}
          />
        )}
        {/* <UploadFile
          buttonTitle={"Update profile picture"}
          type={ImageType.profilePicture}
          onUrlReceived={(url) => update({ ...value, image: url })}
        /> */}
      </div>
      <div>
        <InlineEdit
          value={value.name}
          label={"Name"}
          width={250}
          onConfirm={editName}
          key={"name"}
        />
        <InlineEdit
          value={value.email}
          label={"Email"}
          width={250}
          key={"email"}
          onConfirm={editEmail}
        />
        <InlineEdit
          value={value.phone}
          key={"phone"}
          label={"Phone"}
          width={250}
          onConfirm={editPhone}
        />
        <DefaultPrice id={value.id} />
        <Checkbox label={"GST Included"} />
      </div>
      <div style={{ marginTop: 15 }}>
        <Divider orientation="horizontal" />
        {children}
      </div>
    </div>
  ) : (
    <Loading />
  );
};
