"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditUser = void 0;
const client_1 = require("@apollo/client");
const ProfileFragment_1 = require("./ProfileFragment");
const EDIT_USER = client_1.gql `
mutation EditUserProfile($input: EditUser!) {
    updateUser(input: $input) {
        ...ProfileFragment
    }
}
${ProfileFragment_1.ProfileFragment}
`;
const useEditUser = () => client_1.useMutation(EDIT_USER, {
    refetchQueries: ['Profile']
});
exports.useEditUser = useEditUser;
