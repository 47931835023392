import {
  InlineEdit,
  PageContainer,
  NewButton,
  Loading,
} from "@countingsheep/components-web";
import { useAddShearer } from "@countingsheep/queries";
import React from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

type NewPersonType = {
  email: string;
  phone: string;
  name: string;
};

export const NewPerson: React.FC<{ children?: React.ReactNode }> = () => {
  const h = useHistory();
  const { state } = useLocation();

  const [add, { data, loading }] = useAddShearer();

  const [person, setPerson] = React.useState<NewPersonType>({
    name: "",
    phone: "",
    email: "",
  });

  const onAdd = async () => {
    await add({
      variables: {
        input: {
          name: person.name,
          email: person.email,
          phone: person.phone,
        },
      },
    });
  };

  React.useEffect(() => {
    if (data) {
      if ("returnTo" in (state as any)) {
        h.replace((state as any).returnTo as string);
      } else {
        h.replace(`/team/${data.newShearer.id}`);
      }
    }
  }, [data, h, state]);

  return (
    <PageContainer title={"New Person"}>
      <div
        style={{
          backgroundColor: "#fff",
          padding: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <InlineEdit
              value={""}
              key={"name"}
              label={"Name"}
              width={250}
              onConfirm={(val) => {
                setPerson({ ...person, name: val as string });
              }}
            />
            <InlineEdit
              value={""}
              key={"email"}
              label={"Email"}
              width={250}
              onConfirm={(val) => {
                setPerson({ ...person, email: val as string });
              }}
            />
            <InlineEdit
              value={""}
              key={"phone"}
              label={"Phone"}
              width={250}
              onConfirm={(val) => {
                setPerson({ ...person, phone: val as string });
              }}
            />

            <NewButton onClick={onAdd}>Add</NewButton>
          </>
        )}
      </div>
    </PageContainer>
  );
};
