import * as T from "semantic-ui-react";
import "./tally.scss";

import { TallyTableHeader } from "./TallyTableHeader";
import { TallyDatePicker } from "./TallyDatePicker";
import { TallyContextProvider } from "./TallyContext";
import { TableBody } from "./TallyTableBody";

function Tally() {
  return (
    <TallyContextProvider>
      <T.Container>
        <TallyDatePicker />
        <T.Table celled structured>
          <TallyTableHeader />
          <TableBody />
        </T.Table>
      </T.Container>
    </TallyContextProvider>
  );
}

export default Tally;
