export interface AppConfig {
  BASE_URL: string;
  API_URL: string;
  DEFAULT_EMAIL: string;
  DEFAULT_PASSWORD: string;
  FIREBASE_API_KEY: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_DATABASE_URL: string;
  FIREBASE_AUTH_DOMAIN: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  STRIPE_KEY: string;
  GATEWAY_URL: string;
  AUTH_URL: string;
}

let Config: AppConfig;

if ((window as any).config) {
  const windowConfig: any = (window as any).csConfig;
  Config = windowConfig;
  console.log("window", Config);
} else {
  Config = {
    BASE_URL: process.env.REACT_APP_BASE_URL as string,
    API_URL: process.env.REACT_APP_API_URL as string,
    DEFAULT_EMAIL: process.env.REACT_APP_DEFAULT_EMAIL as string,
    DEFAULT_PASSWORD: process.env.REACT_APP_DEFAULT_PASSWORD as string,
    FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY as string,
    FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
    FIREBASE_DATABASE_URL: process.env
      .REACT_APP_FIREBASE_DATABASE_URL as string,
    FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
    FIREBASE_STORAGE_BUCKET: process.env
      .REACT_APP_FIREBASE_STORAGE_BUCKET as string,
    FIREBASE_MESSAGING_SENDER_ID: process.env
      .REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY as string,
    GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL as string,
    AUTH_URL: process.env.REACT_APP_AUTH_URL as string,
  };
  console.log(Config, "Envi", process.env);
}

export default Config;
