"use strict";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceItemStatus = void 0;
//==============================================================
// START Enums and Input Objects
//==============================================================
var InvoiceItemStatus;
(function (InvoiceItemStatus) {
    InvoiceItemStatus["DRAFT"] = "DRAFT";
    InvoiceItemStatus["INVOICED"] = "INVOICED";
})(InvoiceItemStatus = exports.InvoiceItemStatus || (exports.InvoiceItemStatus = {}));
//==============================================================
// END Enums and Input Objects
//==============================================================
