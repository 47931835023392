"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateInvoice = void 0;
const client_1 = require("@apollo/client");
const CREATE_INVOICE = client_1.gql `
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
    }
  }
`;
const useCreateInvoice = () => {
    return client_1.useMutation(CREATE_INVOICE, {
        refetchQueries: ["MY_INVOICES"],
    });
};
exports.useCreateInvoice = useCreateInvoice;
